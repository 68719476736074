import { GQLFacetsFragment } from "@src/graphql.apollo.generated";
import * as React from "react";

import ModelListItem from "./modelItem/ModelListItem";
import { Root } from "./ModelList.sc";

interface IModelListProps {
    models: GQLFacetsFragment["modelGroups"];
}

const ModelList: React.FunctionComponent<IModelListProps> = ({ models }) => {
    return (
        <Root>
            {models?.map((model) => (
                <ModelListItem model={model} key={model.slug} />
            ))}
        </Root>
    );
};

export default ModelList;

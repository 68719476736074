import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SearchTitleRoot, Title } from "../../SearchResults.sc";

interface SearchTitleProps {
    modelCount: number;
}

const SearchTitle: React.FC<SearchTitleProps> = ({ modelCount }) => {
    const intl = useIntl();

    return (
        <SearchTitleRoot>
            <Title>
                {modelCount}{" "}
                <FormattedMessage
                    id="leaseme.modelList.title"
                    defaultMessage="{total, plural, =1 {{single}} other {{plural}}} in Ihrem Budget"
                    values={{
                        total: modelCount,
                        single: intl.formatMessage({
                            id: "leaseme.modelList.title.single",
                            defaultMessage: "Modell liegt",
                        }),
                        plural: intl.formatMessage({
                            id: "leaseme.modelList.title.plural",
                            defaultMessage: "Modelle liegen",
                        }),
                    }}
                />
            </Title>
        </SearchTitleRoot>
    );
};

export default SearchTitle;

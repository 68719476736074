import Image from "@src/shared/image/Image";
import { Link } from "gatsby";
import styled from "styled-components";

export const Root = styled(Link)`
    display: flex;
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    text-decoration: none;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundLightGray};

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        flex-direction: row;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0 20px;
    }

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        position: relative;
        padding: 0;
        flex-wrap: initial;
    }
`;

export const ModelImage = styled(Image)`
    object-fit: cover;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: 250px;
        margin: 0 30px 0 0;
    }
`;

export const Content = styled.div`
    flex: 1;
    padding: 0 20px;
    margin-top: 20px;
    width: 100%;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        margin-top: 0;
    }

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const Title = styled.h4`
    margin: 0;
    font-size: 18px;
    line-height: 22px;
    margin-top: 10px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-top: 0;
        font-size: 24px;
        line-height: 34px;
    }
`;

export const List = styled.ul`
    margin: 10px 0 10px 16px;
    padding: 0;

    ::marker {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
    }

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
    }
`;

export const ListItem = styled.li`
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0;
`;

export const PriceLinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
`;

export const PriceInfo = styled.p`
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 20px;
`;

export const Price = styled.span`
    font-size: 24px;
    font-weight: 700;
`;

export const ResultsLink = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 15px 20px;
    background: ${({ theme }) => theme.colors.porscheBankBlue};
    justify-content: center;
    width: 100%;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        width: auto;
    }
`;

export const ResultsText = styled.span`
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
`;

import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import React from "react";
import { FormattedMessage } from "react-intl";

import { HeaderRoot, Tab, TabLink, Tabs } from "../../SearchResults.sc";

const Header: React.FC = () => {
    const { filter, generateUrlFromFilter } = useSearchFilter();

    return (
        <HeaderRoot>
            <Tabs>
                <TabLink to={generateUrlFromFilter(filter, "vehicles")}>
                    <FormattedMessage id="leaseme.vehicles.plural" defaultMessage="Fahrzeuge" />
                </TabLink>
                <Tab>
                    <FormattedMessage id="leaseme.models.plural" defaultMessage="Modelle" />
                </Tab>
            </Tabs>
        </HeaderRoot>
    );
};

export default Header;

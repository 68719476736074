import { GQLFacetsFragment } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import { getYear } from "date-fns";
import * as React from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import * as sc from "./ModelListItem.sc";

type ModelGroup = NonNullable<GQLFacetsFragment["modelGroups"]>[number];

interface IModelListItemProps {
    model: ModelGroup;
}

const ModelListItem: React.FunctionComponent<IModelListItemProps> = ({ model }) => {
    const { filter, generateUrlFromFilter } = useSearchFilter();
    const intl = useIntl();

    return (
        <sc.Root
            to={generateUrlFromFilter(
                {
                    ...filter,
                    modelGroups: [model.slug],
                    brands: model.brandSlug ? [model.brandSlug] : undefined,
                },
                "vehicles",
            )}
        >
            <sc.ModelImage alt={model.name} src={model.image || undefined} loadLazy={true} />
            <sc.Content>
                <sc.Title>
                    {model.name} ({model.count})
                </sc.Title>

                <sc.List>
                    <sc.Column>
                        <sc.ListItem>{getPowerPSText(model, intl)}</sc.ListItem>
                        <sc.ListItem>{getMileageText(model, intl)}</sc.ListItem>
                        <sc.ListItem>{getFirstRegistrationText(model, intl)}</sc.ListItem>
                    </sc.Column>
                    <sc.Column>
                        <sc.ListItem>{getFuelTypesText(model, intl)}</sc.ListItem>
                        <sc.ListItem>{getProvincesText(model, intl)}</sc.ListItem>
                    </sc.Column>
                </sc.List>

                <sc.PriceLinkContainer>
                    <sc.PriceInfo>
                        <sc.Price>{getRateText(model, intl)}</sc.Price> / <FormattedMessage id="leaseme.month.single" defaultMessage="Monat" />
                    </sc.PriceInfo>
                    <sc.ResultsLink>
                        <sc.ResultsText>
                            <FormattedMessage id="leaseme.modelList.showVehicles" defaultMessage="Fahrzeuge zeigen" />
                        </sc.ResultsText>
                    </sc.ResultsLink>
                </sc.PriceLinkContainer>
            </sc.Content>
        </sc.Root>
    );
};

export default ModelListItem;

const getPowerPSText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.powerPSRange) {
        return intl.formatMessage({
            id: "leaseme.modelList.power.error",
            defaultMessage: "Fehlende Informationen zur Leistung",
        });
    }

    if (model.powerPSRange.min === model.powerPSRange.max) {
        return `${model.powerPSRange.min} ${intl.formatMessage({
            id: "leaseme.power.short.label",
            defaultMessage: "PS",
        })}`;
    }

    return `${model.powerPSRange.min} ${intl.formatMessage({
        id: "leaseme.to",
        defaultMessage: "bis",
    })} ${model.powerPSRange.max} ${intl.formatMessage({
        id: "leaseme.power.short.label",
        defaultMessage: "PS",
    })}`;
};

const getMileageText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.mileageRange) {
        return intl.formatMessage({
            id: "leaseme.modelList.mileage.error",
            defaultMessage: "Fehlende Informationen zum Kilometerstand",
        });
    }

    if (model.mileageRange.min === model.mileageRange.max) {
        return `${model.mileageRange.min ? intl.formatNumber(model.mileageRange.min) : "-"} ${intl.formatMessage({
            id: "leaseme.km",
            defaultMessage: "km",
        })}`;
    }

    return `${model.mileageRange.min ? intl.formatNumber(model.mileageRange.min) : "-"} ${intl.formatMessage({
        id: "leaseme.to",
        defaultMessage: "bis",
    })} ${model.mileageRange.max ? intl.formatNumber(model.mileageRange.max) : "-"} ${intl.formatMessage({
        id: "leaseme.km",
        defaultMessage: "km",
    })}`;
};

const getFirstRegistrationText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.firstRegistrationRange?.min || !model.firstRegistrationRange?.max) {
        return intl.formatMessage({
            id: "leaseme.modelList.firstRegistration.error",
            defaultMessage: "Fehlende Informationen zur Erstzulassung",
        });
    }

    if (model.firstRegistrationRange.min === model.firstRegistrationRange.max) {
        return `${intl.formatMessage({
            id: "leaseme.firstRegistration.short.label",
            defaultMessage: "EZ",
        })} ${getYear(model.firstRegistrationRange.min)}`;
    }

    return `${intl.formatMessage({
        id: "leaseme.firstRegistration.short.label",
        defaultMessage: "EZ",
    })} ${getYear(model.firstRegistrationRange.min)} ${intl.formatMessage({
        id: "leaseme.to",
        defaultMessage: "bis",
    })} ${getYear(model.firstRegistrationRange.max)}`;
};

const getFuelTypesText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.fuelTypes || model.fuelTypes.length === 0) {
        return intl.formatMessage({
            id: "leaseme.modelList.fuelType.error",
            defaultMessage: "Fehlende Informationen zu den Kraftstoffen",
        });
    } else if (model.fuelTypes.length === 1) {
        return model.fuelTypes[0].name;
    } else {
        const [last, ...other] = [...model.fuelTypes].reverse();

        return `${other
            .reverse()
            .map((fuelType) => fuelType.name)
            .join(", ")} ${intl.formatMessage({
            id: "leaseme.and",
            defaultMessage: "und",
        })} ${last.name}`;
    }
};

const getProvincesText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.provinces || model.provinces.length === 0) {
        return intl.formatMessage({
            id: "leaseme.modelList.provinces.error",
            defaultMessage: "Fehlende Informationen zu den Bundesländern",
        });
    } else if (model.provinces.length === 1) {
        return model.provinces[0].name;
    } else {
        return `${model.provinces.length} ${intl.formatMessage({
            id: "leaseme.province.plural",
            defaultMessage: "Bundesländer",
        })}`;
    }
};

const getRateText = (model: ModelGroup, intl: IntlShape) => {
    if (!model.rateRange) {
        return intl.formatMessage({
            id: "leaseme.modelList.firstRegistration.error",
            defaultMessage: "Fehlende Informationen zur Erstzulassung",
        });
    }

    if (model.rateRange.min === model.rateRange.max) {
        return <FormattedCurrency value={model.rateRange.min || 0} />;
    }

    return (
        <>
            <FormattedCurrency value={model.rateRange.min || 0} />
            {" - "}
            <FormattedCurrency value={model.rateRange.max || 0} />
        </>
    );
};

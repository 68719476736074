import DisclaimerBG from "@src/BG/searchResults/modelSearchResults/disclaimer/Disclaimer";
import { getConfig } from "@src/config";
import { GQLLandingPageFragment, GQLVehicleSearchResultsQuery } from "@src/graphql.apollo.generated";
import { GQLModelSearchResultsPageQuery } from "@src/graphql.gatsby.generated";
import DisclaimerHU from "@src/HU/searchResults/modelSearchResults/disclaimer/Disclaimer";
import DisclaimerRO from "@src/RO/searchResults/modelSearchResults/disclaimer/Disclaimer";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import useSearchResult from "@src/shared/context/searchresult/useSearchResult";
import useSEOContent from "@src/shared/hooks/useSEOContent";
import Header from "@src/shared/searchResults/modelSearchResults/header/Header";
import ModelList from "@src/shared/searchResults/modelSearchResults/modellist/ModelList";
import SearchTitle from "@src/shared/searchResults/modelSearchResults/searchTitle/SearchTitle";
import SearchFilter from "@src/shared/searchResults/searchfilter/SearchFilter";
import SEO from "@src/shared/seo/SEO";
import DisclaimerSI from "@src/SI/searchResults/modelSearchResults/disclaimer/Disclaimer";
import Master from "@src/skeleton/layout/Master";
import { graphql, PageProps } from "gatsby";
import * as React from "react";

import * as sc from "../SearchResults.sc";

export interface ModelSearchResultPageContext {
    initialData: GQLVehicleSearchResultsQuery;
    schemeUrl: GQLLandingPageFragment;
}

const Disclaimer = () => {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return <DisclaimerBG />;
        case "car4me.ro":
            return <DisclaimerRO />;
        case "car4me.si":
            return <DisclaimerSI />;
        case "car4me.porschefinance.hu":
            return <DisclaimerHU />;
        default:
            return null;
    }
};

const ModelSearchResultTemplate: React.FunctionComponent<PageProps<GQLModelSearchResultsPageQuery, ModelSearchResultPageContext>> = ({
    pageContext,
}) => {
    const { schemeUrl } = pageContext;

    const searchResult = useSearchResult();
    const { filter } = useSearchFilter();

    const { title, description } = useSEOContent(schemeUrl, searchResult.totalCount);

    const models = React.useMemo(() => {
        if (!searchResult.facets?.modelGroups) {
            return undefined;
        }

        if (filter.modelGroups) {
            return searchResult.facets.modelGroups.filter((modelGroup) => filter.modelGroups?.includes(modelGroup.slug));
        }

        return searchResult.facets.modelGroups;
    }, [searchResult, filter]);

    return (
        <Master sideBar={<SearchFilter />} marginTop={80} backButtonHome={true}>
            <SEO title={title} description={description} meta={[{ name: "robots", content: "noindex" }]} />
            <Header />
            <sc.Content>
                {models && (
                    <>
                        <SearchTitle modelCount={models.length} />
                        <ModelList models={models} />
                    </>
                )}
                <Disclaimer />
            </sc.Content>
        </Master>
    );
};

export default ModelSearchResultTemplate;

export const query = graphql`
    query ModelSearchResultsPage($leasemeScope: LeaseMe_ContentScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
        }
    }
`;
